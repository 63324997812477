import request from '@/router/axios';
import { businessUrl } from '@/config/env';
//����
export const calssList = () => {
    return request({
        url: businessUrl + "department/selectWorkShopName?type=1",
        method: "get",
        parmas: {
            type: 0
        }
    })
}
//��ȡ�б�
export const getList = (pageNow,pageSize,parmas) => {
    return request({
        url: businessUrl + "reportCondition/selectReportCondition",
        method: "post",
        data: {
            pageNo: pageNow,
            pageSize:pageSize,
            ...parmas
        }
    })
}
// 查询品种明细
export const getVarietyDetails = () => {
    return request({
        url: businessUrl + "product/selectAll",
        method: "get"
    })
}
// 查询品种分类
export const getproductCategory = () => {
    return request({
        url: businessUrl + "/productCategory/selectAll",
        method: "get"
    })
}
// 查询批号是否重复
export const  selectIsBatchByCode = (batchCode) => {
    return request({
        url: businessUrl + "productBatch/selectIsBatchByCode",
        method: "get",
        params: {
            batchCode  
        }
    })
}
export const getcorpUsers = () => {
    return request({
        url: businessUrl + "corpUsers/selectUserByCorpId",
        method: "post"
    })
}
// 查询接口
export const getSearch = (obj) => {
    return request({
        url: businessUrl + "reportCondition/selectReport",
        method: "post",
        data: {
            ...obj
            // strTime,endTime,granularity,workShopId,shiftId,machineId,groupId,productId,productCategoryId,userId,batchId,isMachine,isShift,isGroup,isUser,isProductCategory,isProduct,isBatch
        }
    })
}